import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Curve from '../svgs/Curve';
import * as styles from './KaguraCurve.module.scss';

function KaguraCurve(props) {
  const data = useStaticQuery(graphql`
    query {
      fallingDrop: file(relativePath: { eq: "bg-drop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
  `);
  const { fallingDrop: dropBG } = data;
  const { className: classes, hasAnimation } = props;
  return (
    <div className={[styles.curveWrap, classes].join(' ')}>
      <div className={styles.curveSvgWrap}>
        <Curve />
      </div>
      {hasAnimation && (
        <>
          <div className={styles.dropOrigin} />
          <div className={styles.fallingDrop}>
            <GatsbyImage className={styles.dropImage} loading="eager" image={getImage(dropBG)} alt="Drop" />
          </div>
        </>
      )}
    </div>
  );
}
KaguraCurve.defaultProps = {
  className: '',
  hasAnimation: true,
};
KaguraCurve.propTypes = {
  className: PropTypes.string,
  hasAnimation: PropTypes.bool,
};
export default KaguraCurve;
