import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { PropTypes } from 'prop-types';

import RestaurantAmenities from '../../components/restaurant/RestaurantAmenities';

import * as styles from './RestaurantsListItem.module.scss';

function RestaurantsListItem(props) {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "placeholders/restaurant-thumb.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1, layout: CONSTRAINED)
        }
      }
    }
  `);
  const [isHovered, setHovered] = useState(false);
  const { restaurant } = props;

  const {
    slug,
    language: {
      slug: langCode,
    },
    restaurantDetails,
  } = restaurant;

  // Get restaurant image
  const { featuredImage } = restaurantDetails;
  const { localFile: restaurantImage, altText } = featuredImage || { localFile: data.placeholderImage, altText: 'Restaurant Image' };

  // Get link URL
  const linkURL = `/${langCode.toLowerCase()}/${slug}/`;

  // Amenities classes
  const hasAmenities = () => {
    const {
      terraza, wheelchair, cocktails, wifi,
    } = restaurantDetails;
    return terraza || wheelchair || cocktails || wifi;
  };
  const marginClass = hasAmenities() ? '' : styles.noAmenities;
  const hoveredClass = isHovered ? styles.isHovered : '';

  /**
   * Optimisation needed
   * Try using css hover class to change color of SVG
   */
  const onMouseOverHandler = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setHovered(true);
    }
  };
  const onMouseLeaveHandler = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setHovered(false);
    }
  };

  return (
    <div
      key={restaurant.id}
      className={[
        styles.restaurantItem,
        'align-items-end d-flex restaurant-list-item',
      ].join(' ')}
    >
      <div
        onFocus={onMouseOverHandler}
        onMouseOver={onMouseOverHandler}
        onBlur={onMouseLeaveHandler}
        onMouseOut={onMouseLeaveHandler}
        className={[marginClass, hoveredClass].join(' ')}
        role='presentation'
      >
        <Link to={linkURL} className={styles.restaurantLink}>
          <h4 className={styles.restaurantTitle}>{restaurant.name}</h4>
          <div className={styles.restaurantThumbWrap}>
            <GatsbyImage
              imgClassName={styles.restaurantThumb}
              image={getImage(restaurantImage)}
              alt={altText}
            />
          </div>
          <RestaurantAmenities
            isHovered={isHovered}
            restaurantDetails={restaurant.restaurantDetails}
          />
        </Link>
      </div>
    </div>
  );
}

RestaurantsListItem.propTypes = {
  restaurant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    language: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    restaurantDetails: PropTypes.shape({
      terraza: PropTypes.bool,
      wheelchair: PropTypes.bool,
      cocktails: PropTypes.bool,
      wifi: PropTypes.bool,
      featuredImage: PropTypes.shape({
        localFile: PropTypes.shape({}).isRequired,
        altText: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};
export default RestaurantsListItem;
