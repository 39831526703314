import React from 'react';
import * as styles from './RestaurantAmenities.module.scss';

import IconCocktails from '../elements/icons/Cocktails';
import IconTerraza from '../elements/icons/Terraza';
import IconWifi from '../elements/icons/Wifi';
import IconWheelchair from '../elements/icons/Wheelchair';

function RestaurantAmenities(props) {
  const {
    terraza, cocktails, wheelchair, wifi,
  } = props.restaurantDetails;
  const fillColor = props.isHovered ? '#ffed00' : '#000000';
  const render = wifi || wheelchair || cocktails || terraza;
  if (render) {
    return (
      <ul className={styles.restaurantAmenitiesWrap}>
        {terraza && (
          <li>
            <IconTerraza fill={fillColor} />
          </li>
        )}
        {cocktails && (
          <li>
            <IconCocktails fill={fillColor} />
          </li>
        )}
        {wheelchair && (
          <li>
            <IconWheelchair fill={fillColor} />
          </li>
        )}
        {wifi && (
          <li>
            <IconWifi fill={fillColor} />
          </li>
        )}
      </ul>
    );
  }
  return '';
}
export default RestaurantAmenities;
