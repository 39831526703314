// extracted by mini-css-extract-plugin
export var centerCol = "HomeHeader-module--center-col--5eaa2";
export var dropFalling = "HomeHeader-module--drop-falling--b71bd";
export var dropImage = "HomeHeader-module--drop-image--a6524";
export var dropOrigin = "HomeHeader-module--drop-origin--dd3ec";
export var dropOriginContainer = "HomeHeader-module--drop-origin-container--fc51f";
export var en = "HomeHeader-module--en--625a9";
export var es = "HomeHeader-module--es--dbe82";
export var fallingDrop = "HomeHeader-module--falling-drop--d6caa";
export var langSwitcher = "HomeHeader-module--lang-switcher--9c269";
export var logo = "HomeHeader-module--logo--7c22d";
export var menuItem = "HomeHeader-module--menu-item--4b657";
export var menuLink = "HomeHeader-module--menu-link--32390";
export var menuList = "HomeHeader-module--menu-list--f4bad";
export var mobileMenuList = "HomeHeader-module--mobile-menu-list--ffa8b";