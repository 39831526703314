import React, { useContext } from 'react';
import {
  useStaticQuery, graphql, Link, navigate,
} from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './HomeHeader.module.scss';
import MenuContext from '../../context/menu-context';

function HomeHeader() {
  const data = useStaticQuery(graphql`
    query {
      homeLogo: file(
        relativePath: { eq: "branding/kagura-logo-home.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
      fallingDrop: file(relativePath: { eq: "bg-drop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
      homeLeftMenuEN: wpMenu(locations: {eq: PRIMARY_NAV_LEFT___EN}) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
      homeLeftMenuES: wpMenu(locations: {eq: PRIMARY_NAV_LEFT}) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
      homeRightMenuEN: wpMenu(locations: {eq: PRIMARY_NAV_RIGHT___EN}) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
      homeRightMenuES: wpMenu(locations: {eq: PRIMARY_NAV_RIGHT}) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
    }
  `);
  const {
    fallingDrop: dropBG,
    homeLogo,
    homeLeftMenuEN,
    homeLeftMenuES,
    homeRightMenuEN,
    homeRightMenuES,
  } = data;
  const ctx = useContext(MenuContext);

  const {
    languages,
    currentLangCode,
    switchLanguage,
  } = ctx;

  const renderLangChangeButton = () => {
    if (languages && languages.length && currentLangCode) {
      return (
        <>
          {languages.map((item) => {
            if (currentLangCode !== item.code.toLowerCase()) {
              return (
                <li className={styles.menuItem} key={item.code}>
                  <button
                    className={[styles.langSwitcher, styles[item.code.toLowerCase()]].join(' ')}
                    type="button"
                    onClick={() => {
                      switchLanguage(item.code.toLowerCase());
                      navigate(item.homeUrl);
                    }}
                  >
                    {item.name}
                  </button>
                </li>
              );
            }
            return null;
          }) }
        </>
      );
    }
    return null;
  };

  const renderLeftMenu = () => {
    const { menuItems } = ctx.currentLangCode === 'en' ? homeLeftMenuEN : homeLeftMenuES;
    const { nodes: menuItemsArr } = menuItems || { nodes: [] };

    if (!menuItemsArr.length) { return null; }

    return (
      <ul className={[styles.menuList, 'justify-content-end'].join(' ')}>
        {menuItemsArr.map((menuItem) => (
          <li className={styles.menuItem} key={menuItem.id}>
            <Link className={styles.menuLink} to={menuItem.path}>{menuItem.label}</Link>
          </li>
        ))}
      </ul>
    );
  };

  const renderRightMenu = () => {
    const { menuItems } = ctx.currentLangCode === 'en' ? homeRightMenuEN : homeRightMenuES;
    const { nodes: menuItemsArr } = menuItems || { nodes: [] };

    if (!menuItemsArr.length) { return null; }

    return (
      <ul className={[styles.menuList, 'justify-content-start'].join(' ')}>
        {menuItemsArr.map((menuItem) => (
          <li className={styles.menuItem} key={menuItem.id}>
            <Link className={styles.menuLink} to={menuItem.path}>{menuItem.label}</Link>
          </li>
        ))}
        {renderLangChangeButton()}
      </ul>
    );
  };

  const renderMobileMenu = () => {
    const {
      mobileNav: { menuItems },
    } = ctx;
    const { nodes: menuItemsArr } = menuItems || { nodes: [] };

    if (!menuItemsArr.length) { return null; }

    return (
      <ul className={styles.mobileMenuList}>
        {menuItemsArr.map((menuItem) => (
          <li className={styles.menuItem} key={menuItem.id}>
            <Link className={styles.menuLink} to={menuItem.path}>{menuItem.label}</Link>
          </li>
        ))}
        {renderLangChangeButton()}
      </ul>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col flex-grow-1 d-none d-xl-flex px-4 align-items-center justify-content-end">
          {renderLeftMenu()}
        </div>
        <div className={[styles.centerCol, 'col col-12 col-xl-auto'].join(' ')}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className={styles.dropOriginContainer}>
              <GatsbyImage image={getImage(homeLogo)} loading="eager" alt="Ramen Kagura" />
              <div className={styles.dropOrigin} />
              <div className={styles.fallingDrop}>
                <GatsbyImage className={styles.dropImage} loading="eager" image={getImage(dropBG)} alt="Drop" />
              </div>
            </div>
            <div className="d-xl-none my-5">
              {renderMobileMenu()}
            </div>
          </div>
        </div>
        <div className="col flex-grow-1 d-none d-xl-flex px-4 align-items-center justify-content-start">
          {renderRightMenu()}
        </div>
      </div>
    </div>
  );
}

export default HomeHeader;
