import React from 'react';
import PropTypes from 'prop-types';

import KaguraCurve from '../../components/presentation/KaguraCurve';
import * as styles from './Header.module.scss';

function Header({ children, className: classes }) {
  return (
    <div className={[styles.header, classes].join(' ')}>
      <div className={styles.headerContentWrap}>
        {children}
      </div>
      <KaguraCurve hasAnimation={false} />
    </div>
  );
}

Header.defaultProps = {
  children: '',
  className: '',
};
Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
export default Header;
