import React from 'react';
import PropTypes from 'prop-types';

function Curve({ fill, stroke }) {
  return (
    <svg
      width="1433"
      height="115"
      viewBox="0 0 1433 115"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 0.5C41.2539 17.3041 111 26.5 211 34C311 41.5 432 38.5 432 38.5C551.575 38.6356 603.879 41.468 654.5 54.5C668.418 58.2493 690.5 66 698 74C701.057 77.2609 702.5 81 700 88C697.5 95 695.5 115 716.5 115V0.5H0.5Z" />
      <path d="M716.5 0.5V115C737.5 115 735.5 95 733 88C730.5 81 731.943 77.2609 735 74C742.5 66 764.582 58.2493 778.5 54.5C829.121 41.468 881.425 38.6356 1001 38.5C1001 38.5 1122 41.5 1222 34C1322 26.5 1391.75 17.3041 1432.5 0.5L716.5 0.5Z" />
    </svg>
  );
}

Curve.defaultProps = {
  fill: '#ffed00',
  stroke: '#ffed00',
};
Curve.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
};
export default Curve;
