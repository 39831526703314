import React from 'react';
import PropTypes from 'prop-types';

function BrandTitle({ fill, stroke }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 4063 484"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      fill={fill}
      stroke={stroke}
    >
      <g transform="matrix(1,0,0,1,-20558.7,-24208.7)">
        <g transform="matrix(8.33333,0,0,8.33333,18558.2,22629.8)">
          <g transform="matrix(1,0,0,1,550.452,216.7)">
            <path
              d="M0,3.238C-1.796,-1.863 -3.349,-6.273 -4.902,-10.683C-5.224,-10.639 -5.546,-10.594 -5.869,-10.55C-7.175,-5.698 -8.482,-0.847 -9.927,4.518C-6.319,4.053 -3.327,3.667 0,3.238M-5.304,-26.259C-1.212,-19.958 3.24,-13.852 6.819,-7.272C8.586,-4.024 8.737,0.102 9.627,3.829C10.114,5.865 10.653,7.889 11.104,9.933C11.831,13.224 12.619,16.508 13.169,19.829C13.788,23.565 12.115,26.266 9.071,26.878C6.42,27.411 2.704,24.702 1.996,21.562C1.551,19.591 1.357,17.564 0.947,14.89C-3.056,15.804 -6.809,16.483 -10.413,17.628C-11.164,17.867 -11.554,19.796 -11.78,21.008C-12.134,22.906 -11.845,24.999 -12.525,26.737C-12.994,27.937 -14.635,29.478 -15.757,29.482C-20.405,29.497 -24.512,24.265 -24.563,19.542C-24.639,12.59 -22.575,6.123 -20.025,-0.084C-16.376,-8.97 -11.978,-17.549 -7.9,-26.259L-5.304,-26.259"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,709.389,231.071)">
            <path
              d="M0,-25.209C-0.275,-25.242 -0.55,-25.276 -0.826,-25.309C-2.159,-20.357 -3.491,-15.405 -4.944,-10.008C-2.148,-10.265 0.068,-10.387 2.257,-10.696C3.831,-10.918 4.83,-11.665 4.134,-13.559C2.712,-17.426 1.373,-21.324 0,-25.209M-0.696,-40.63C4.01,-33.98 10.119,-28.211 12.515,-20.107C13.202,-17.782 12.758,-14.963 13.886,-12.964C15.485,-10.128 15.219,-7.061 16.081,-4.175C17.049,-0.931 17.654,2.453 18.077,5.818C18.554,9.612 16.896,11.972 13.84,12.52C11.242,12.987 7.754,10.427 6.999,7.328C6.496,5.265 6.24,3.141 5.79,0.573C1.964,1.4 -1.587,2.087 -5.081,2.995C-5.719,3.161 -6.367,4.157 -6.58,4.894C-6.933,6.118 -6.763,7.494 -7.126,8.713C-7.769,10.874 -8.144,14.178 -9.558,14.763C-11.15,15.421 -13.929,13.897 -15.907,12.806C-21.674,9.626 -19.914,4.089 -19.098,-0.506C-17.139,-11.541 -12.957,-21.886 -7.723,-31.797C-6.185,-34.71 -4.766,-37.684 -3.292,-40.63L-0.696,-40.63"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,578.184,246.435)">
            <path
              d="M0,-53.217C1.859,-53.606 3.717,-54.313 5.579,-54.323C11.19,-54.352 16.319,-52.515 20.69,-49.073C23.577,-46.799 22.434,-36.643 19.077,-35.089C17.86,-34.525 15.656,-35.248 14.303,-36.055C12.896,-36.895 12.083,-38.668 10.816,-39.819C7.466,-42.864 4.741,-42.791 1.992,-39.317C-2.307,-33.884 -4.228,-27.684 -2.204,-20.874C-1.673,-19.088 0.034,-17.194 1.708,-16.29C6.921,-13.475 14.278,-15.982 17.021,-21.213C15.441,-20.862 13.946,-20.396 12.418,-20.218C7.689,-19.665 4.255,-22.133 3.159,-26.745C2.416,-29.869 4.995,-32.182 8.357,-31.878C10.96,-31.642 13.717,-31.639 16.235,-32.244C22.77,-33.812 26.076,-31.07 25.943,-24.418C25.824,-18.453 26.267,-12.468 26.651,-6.504C26.837,-3.602 23.781,0.87 20.809,1.068C19.797,1.135 18.37,0.362 17.747,-0.481C16.961,-1.543 16.805,-3.069 16.554,-3.847C12.443,-3.351 8.494,-2.859 4.54,-2.416C4.044,-2.36 3.394,-2.393 3.026,-2.667C-0.919,-5.615 -5.191,-8.249 -8.646,-11.698C-13.527,-16.57 -12.929,-23.228 -12.68,-29.44C-12.523,-33.373 -11.801,-37.372 -10.688,-41.152C-9.658,-44.647 -7.269,-47.442 -4.197,-49.642C-2.703,-50.712 -1.606,-52.338 -0.329,-53.713C-0.219,-53.547 -0.11,-53.382 0,-53.217"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,497.5,225.382)">
            <path
              d="M0,-13.174C6.028,-18.118 9.618,-23.859 13.178,-29.573C14.413,-31.557 15.762,-32.631 18.079,-32.465C20.472,-32.294 21.222,-30.578 21.887,-28.646C25.014,-19.567 21.282,-12.269 11.864,-8.582C10.343,-7.986 9.274,-6.233 7.996,-5.014C9.083,-3.921 10.164,-2.82 11.26,-1.735C12.384,-0.622 13.441,0.58 14.679,1.548C15.683,2.333 16.869,3.256 18.051,3.378C23.501,3.944 24.808,7.59 25.466,12.218C25.886,15.182 25.602,17.627 22.911,19.161C20.698,20.421 16.224,18.97 14.411,16.936C9.993,11.981 5.469,7.122 0.987,2.224C0.697,2.377 0.406,2.529 0.115,2.682C0.591,4.698 1.061,6.714 1.543,8.729C1.764,9.654 2.161,10.565 2.213,11.497C2.486,16.404 1.488,18.486 -1.37,19.246C-3.413,19.79 -8.148,16.984 -9.857,14.735C-12.24,11.599 -11.981,8.063 -11.922,4.548C-11.774,-4.273 -11.642,-13.098 -11.225,-21.908C-11.085,-24.862 -10.334,-27.855 -9.392,-30.672C-8.996,-31.857 -7.485,-33.197 -6.287,-33.418C-5.328,-33.595 -3.714,-32.437 -3.022,-31.435C-0.76,-28.162 1.133,-24.763 0.083,-20.393C-0.403,-18.372 0,-16.138 0,-13.174"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,659.962,231.111)">
            <path
              d="M0,-25.157C0.48,-21.946 0.88,-19.263 1.208,-17.071C2.449,-17.071 3.694,-17.172 4.916,-17.05C7.164,-16.825 9.298,-17.173 9.715,-19.662C10.06,-21.723 10.212,-24.272 9.266,-25.927C8.689,-26.934 5.835,-27.004 4.062,-26.848C2.552,-26.714 1.123,-25.655 0,-25.157M-0.137,-7.601C-0.137,-3.573 -0.157,0.454 -0.124,4.481C-0.115,5.558 0.205,6.645 0.12,7.706C-0.072,10.107 0.381,12.979 -2.974,13.695C-5.462,14.225 -8.863,12.397 -9.485,9.507C-10.31,5.676 -10.77,1.638 -10.543,-2.262C-10.144,-9.089 -9.031,-15.871 -8.394,-22.689C-8.28,-23.914 -8.736,-25.402 -9.415,-26.455C-12.104,-30.628 -11.274,-33.677 -6.884,-35.858C-2.57,-38.001 1.831,-39.566 6.734,-39.328C12.96,-39.027 17.075,-35.977 18.995,-30.084C20.541,-25.337 20.601,-20.405 19.211,-15.747C18.536,-13.481 16.016,-11.807 14.538,-9.717C13.938,-8.869 13.303,-7.261 13.697,-6.634C15.058,-4.467 16.617,-2.268 18.595,-0.707C19.755,0.208 21.862,-0.12 23.522,0.219C24.448,0.408 26.13,0.875 26.112,1.118C25.868,4.489 28.18,8.068 24.504,11.196C20.726,14.409 16.49,14.213 13.698,10.07C10.155,4.81 6.446,-0.34 2.77,-5.51C2.18,-6.341 1.392,-7.033 0.696,-7.79C0.418,-7.727 0.141,-7.664 -0.137,-7.601"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,634.095,242.796)">
            <path
              d="M0,-48.35C0.784,-48.502 1.83,-48.706 3.198,-48.971C3.467,-48.763 4.143,-47.816 4.955,-47.681C8.101,-47.159 8.531,-44.545 9.016,-42.307C9.803,-38.667 9.905,-34.881 10.663,-31.232C12.124,-24.203 10.184,-17.617 8.367,-11.015C6.014,-2.466 -1.836,2.376 -11.716,1.543C-19.484,0.888 -24.486,-4.944 -25.907,-13.637C-27.166,-21.336 -26.44,-28.832 -25.442,-36.41C-25.235,-37.985 -25.723,-39.649 -25.538,-41.231C-25.224,-43.925 -24.992,-46.74 -23.958,-49.179C-23.596,-50.033 -20.488,-50.726 -19.472,-50.11C-16.415,-48.258 -13.755,-45.597 -13.541,-41.717C-13.342,-38.117 -13.282,-34.423 -13.868,-30.887C-14.861,-24.884 -16.009,-18.906 -13.258,-13.177C-12.499,-11.598 -10.3,-9.53 -8.988,-9.679C-7.123,-9.89 -4.885,-11.579 -3.824,-13.274C0.504,-20.191 -0.286,-27.94 -0.948,-35.596C-1.205,-38.569 -1.705,-41.535 -1.722,-44.507C-1.729,-45.669 -0.714,-46.836 0,-48.35"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,367.045,221.829)">
            <path
              d="M0,-11.025C0.4,-12.827 0.767,-14.637 1.206,-16.429C2.09,-20.035 2.745,-23.733 4.05,-27.182C4.579,-28.582 6.41,-30.052 7.909,-30.417C12.733,-31.592 16.971,-26.987 16.433,-22.015C16.162,-19.511 16.911,-16.907 17.103,-14.341C17.283,-11.942 17.379,-9.534 17.437,-7.129C17.606,-0.034 17.687,7.062 17.908,14.154C17.992,16.833 16.19,20.295 14.334,20.518C12.471,20.742 8.565,17.772 8.424,15.533C8.221,12.312 8.642,9.056 8.586,5.819C8.511,1.544 8.233,-2.726 8.042,-6.999C7.689,-7.024 7.337,-7.049 6.984,-7.075C6.122,-2.045 5.411,3.018 4.338,8.003C3.726,10.851 2.666,13.669 -1.23,13.437C-4.323,13.252 -6.91,10.953 -7.283,7.778C-7.395,6.832 -7.239,5.856 -7.231,4.894C-7.218,3.495 -7.228,2.095 -7.228,0.695C-7.55,0.68 -7.873,0.665 -8.196,0.649C-8.389,5.273 -8.931,9.918 -8.644,14.512C-8.466,17.356 -9.578,18.613 -11.841,19.235C-14.524,19.972 -16.992,19.31 -18.153,16.595C-18.837,14.998 -19.23,13.058 -19.051,11.346C-17.97,0.981 -16.747,-9.372 -15.427,-19.71C-15.11,-22.196 -14.429,-24.677 -13.595,-27.048C-12.249,-30.877 -9.139,-31.767 -5.873,-29.374C-3.043,-27.301 -1.18,-24.92 -1.671,-20.907C-2.062,-17.711 -1.377,-14.384 -1.169,-11.115C-0.78,-11.085 -0.39,-11.055 0,-11.025"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,401.408,195.855)">
            <path
              d="M0,42.566C-0.034,42.608 -0.323,42.85 -0.462,43.16C-2.761,48.289 -5.169,48.765 -8.862,44.431C-12.588,40.059 -14.162,34.833 -13.43,29.088C-12.3,20.215 -11.067,11.354 -9.811,2.498C-9.205,-1.778 -6.927,-2.919 -2.661,-2.468C-0.069,-2.194 2.664,-3.281 5.338,-3.733C8.777,-4.315 12.781,-6.199 15.495,-5.05C17.95,-4.011 19.483,0.21 20.389,3.328C20.683,4.339 17.968,7.09 16.184,7.666C12.101,8.982 7.764,9.585 3.492,10.223C-1.534,10.975 -1.552,10.858 -1.305,16.675C0.534,16.505 2.368,16.455 4.151,16.126C5.303,15.914 6.385,15.305 7.491,14.857C14.056,12.192 18.408,14.926 18.48,22.032C18.49,23.023 16.97,24.556 15.848,24.948C8.487,27.516 6.205,28.743 -1.899,24.175C-1.719,27.287 -1.552,30.171 -1.336,33.904C2.708,33.15 6.385,32.439 10.073,31.786C12.318,31.388 14.582,31.098 16.833,30.727C19.034,30.365 20.786,30.675 21.9,33C23.206,35.724 25.304,38.899 22.626,41.155C20.289,43.123 16.677,44.378 13.589,44.46C9.248,44.576 4.87,43.316 0,42.566"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,439.584,218.397)">
            <path
              d="M0,-3.592C0.338,2.27 0.698,8.132 0.994,13.996C1.048,15.066 0.93,16.165 0.739,17.223C-0.185,22.344 -4.522,24.707 -9.208,22.239C-10.495,21.561 -11.688,19.209 -11.617,17.687C-11.085,6.439 -10.193,-4.791 -9.497,-16.033C-9.387,-17.809 -9.805,-19.614 -9.782,-21.403C-9.725,-25.787 -5.574,-28.299 -1.72,-26.156C2.272,-23.937 5.294,-20.949 6.305,-16.1C7.059,-12.488 8.575,-9.029 9.852,-5.539C10.231,-4.504 10.951,-3.594 11.75,-2.224C12.859,-9.623 13.847,-16.374 14.892,-23.116C15.341,-26.01 17.585,-27.164 19.921,-25.455C22.642,-23.464 24.178,-20.756 23.778,-17.106C22.589,-6.263 21.455,4.589 20.469,15.452C20.239,17.988 19.921,20.232 17.513,21.596C13.035,24.132 10.054,23.093 8.369,18.17C6.268,12.034 4.397,5.819 2.348,-0.336C1.924,-1.609 1.156,-2.767 0.546,-3.977C0.364,-3.849 0.182,-3.721 0,-3.592"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,250.861,227.618)">
            <path
              d="M0,-23.071C0.497,-20.325 0.912,-18.026 1.32,-15.771C4.473,-15.643 8.139,-14.344 9.28,-18.559C9.742,-20.269 9.405,-23.197 8.256,-24.142C5.526,-26.386 2.643,-24.427 0,-23.071M10.629,-8.372C12.656,-5.796 14.468,-2.718 17.033,-0.518C18.603,0.827 21.329,0.822 24.629,1.722C24.629,3.594 25.322,6.904 24.42,9.699C23.633,12.142 20.971,14.353 17.86,13.398C15.909,12.799 13.852,11.415 12.627,9.791C8.628,4.491 4.99,-1.081 1.214,-6.548C0.818,-6.38 0.422,-6.213 0.025,-6.045C0.025,-2.156 0.018,1.734 0.031,5.623C0.034,6.58 0.397,7.646 0.087,8.471C-0.618,10.346 -1.639,12.103 -2.532,13.908C-4.714,12.689 -7.063,11.682 -9.003,10.155C-9.849,9.49 -10.118,7.778 -10.203,6.511C-10.811,-2.48 -10.558,-11.41 -8.367,-20.235C-8.053,-21.5 -8.577,-23.251 -9.281,-24.441C-11.843,-28.771 -11.25,-30.964 -6.87,-33.415C-2.23,-36.012 2.608,-37.424 7.935,-36.671C12.467,-36.03 15.784,-33.55 17.445,-29.347C20.11,-22.602 20.064,-15.879 15.57,-9.868C14.773,-8.803 12.45,-8.879 10.629,-8.372"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,293.107,214.119)">
            <path
              d="M0,4.461C1.241,4.245 1.806,4.06 2.371,4.062C8.73,4.084 9.002,3.706 6.852,-2.185C6.566,-2.968 6.418,-3.801 6.137,-4.586C5.586,-6.122 4.987,-7.64 4.407,-9.166C4.139,-9.115 3.87,-9.065 3.602,-9.015C2.455,-4.724 1.308,-0.434 0,4.461M-14.271,17.577C-12.722,5.717 -8.54,-5.266 -3.061,-15.826C-1.852,-18.154 -0.917,-20.636 0.421,-22.882C1.826,-25.239 4.029,-25.147 5.421,-23.121C9.047,-17.843 12.54,-12.45 15.679,-6.873C16.842,-4.807 16.836,-2.093 17.425,0.31C18.299,3.871 19.313,7.4 20.109,10.977C20.877,14.428 21.824,17.907 21.975,21.404C22.085,23.959 20.201,25.781 17.446,26.162C15.184,26.474 11.597,23.836 10.941,21.402C10.395,19.374 10.345,15.92 9.3,15.596C7.269,14.965 4.707,16.264 2.341,16.441C-1.061,16.696 -2.096,18.696 -2.111,21.697C-2.115,22.538 -2.132,23.383 -2.216,24.219C-2.659,28.631 -4.506,29.7 -8.383,27.811C-13.749,25.197 -14.666,23.576 -14.271,17.577"
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1,0,0,1,333.73,221.695)">
            <path
              d="M0,-11.684C3.093,-11.352 5.771,-11.552 8.023,-10.667C9.814,-9.962 12.236,-7.994 12.32,-6.474C12.454,-4.048 12.818,-0.194 8.46,-0.082C5.265,0 2.063,-0.245 -1.137,-0.268C-4.815,-0.294 -8.494,-0.266 -12.172,-0.203C-15.522,-0.146 -16.562,-1.374 -16.994,-5.35C-17.342,-8.563 -16.448,-10.441 -12.931,-10.652C-8.42,-10.922 -3.919,-11.364 0,-11.684"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

BrandTitle.defaultProps = {
  fill: 'rgb(35,31,32)',
  stroke: 'rgb(35,31,32)',
};
BrandTitle.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
};
export default BrandTitle;
