import React from "react";

const Wheelchair = ({ fill, stroke }) => {
  fill = fill || "#000000";
  stroke = stroke || "#000000";
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 46 50"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
    >
      <path
        d="m0-1.552c-.839.159-1.418.899-1.349 1.727.073.889.815 1.596 1.692 1.615.823.018 1.636-.652 1.731-1.425.064-.517-.129-.737-.656-.746-1.207-.022-1.207-.022-1.418-1.171m.6-.648c.476 0 .784-.033 1.079.015.135.022.243.206.363.317-.118.09-.228.24-.356.256-.297.039-.602.013-.942.013v.64c1.057-.198 1.919 0 2.083 1.146.341.081.584.138.827.196-.05.119-.07.309-.155.346-.316.136-.609.576-1.036.105-.465.843-1.096 1.419-2.119 1.392-1.095-.028-1.879-.693-2.12-1.823-.193-.908.348-1.98 1.276-2.32.61-.223.719-.447.359-.997-.106-.162-.028-.602.123-.732.156-.135.606-.159.741-.032.167.156.189.519.183.791-.005.191-.161.378-.306.687"
        fillRule="nonzero"
        transform="matrix(8.33333 0 0 8.33333 15.123195176 31.34287462)"
      />
    </svg>
  );
};

export default Wheelchair;
