import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Header from '../global/Header';
import BrandTitle from '../../components/svgs/BrandTitle';

import * as styles from './HomeRestaurantsHeader.module.scss';

function HomeRestaurantsHeader() {
  const data = useStaticQuery(graphql`
    query {
      redIcon: file(relativePath: { eq: "branding/kagura-icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
  `);
  const { redIcon } = data;
  return (
    <Header>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={[styles.brandTitleWrap, 'pt-4'].join(' ')}>
              <BrandTitle fill="#ffffff" stroke="#ffffff" />
              <GatsbyImage className={styles.kaguraIcon} image={getImage(redIcon)} alt="Ramen Kagura" />
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default HomeRestaurantsHeader;
