import React from 'react';
import PropTypes from 'prop-types';
import RestaurantsListItem from './RestaurantsListItem';
import EmptyContent from '../misc/EmptyContent';

import * as styles from './RestaurantsList.module.scss';

function RestaurantsList(props) {
  const { restaurants } = props;

  const content = restaurants.length ? (
    <div className={styles.restaurantsListContainer}>
      <div className={styles.restaurantsListRow}>
        {restaurants.map((restaurant) => (
          <RestaurantsListItem key={restaurant.id} restaurant={restaurant} />
        ))}
      </div>
    </div>
  ) : (
    <EmptyContent>
      <h4 className="text-center">No restaurants found.</h4>
    </EmptyContent>
  );

  return <div className="section-content">{content}</div>;
}

RestaurantsList.propTypes = {
  restaurants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
};
export default RestaurantsList;
