import React from 'react';
import PropTypes from 'prop-types';
import BackgroundVideo from '../../components/elements/BackgroundVideo';
import BackgroundImage from '../../components/elements/BackgroundImage';

function BackgroundMedia(props) {
  const { background } = props;

  const renderBackgroundMedia = () => {
    if (background && background.length) {
      const firstBG = background.find(() => true);
      switch (firstBG.fieldGroupName) {
        case 'Template_Home_Homepagefields_Background_Video':
          return (
            <BackgroundVideo videoURL={firstBG.backgroundVideoUrl} />
          );
        case 'Template_Home_Homepagefields_Background_Image':
          return (
            <BackgroundImage
              image={firstBG.backgroundImage.localFile}
              alt={firstBG.backgroundImage.altText}
            />
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  return renderBackgroundMedia();
}
BackgroundMedia.propTypes = {
  background: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default BackgroundMedia;
