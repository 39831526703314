import React from "react";

const Wifi = ({ fill, stroke }) => {
  fill = fill || "#000000";
  stroke = stroke || "#000000";
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 67 52"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
    >
      <g fillRule="nonzero">
        <path
          d="m0-2.549c1.574-.005 2.809.484 3.866 1.436.238.214.48.449.208.772-.295.351-.567.133-.835-.089-1.954-1.625-4.257-1.615-6.213.032-.264.223-.57.39-.768.06-.099-.164.016-.602.182-.759 1.033-.975 2.279-1.462 3.56-1.452"
          transform="matrix(8.33333 0 0 8.33333 31.492393063 21.24121263)"
        />
        <path
          d="m0 1.173c-.13.153-.222.36-.327.366-.191.012-.436-.041-.576-.163-.762-.656-1.642-.923-2.603-.703-.514.117-.998.414-1.461.689-.271.161-.498.421-.775.078-.263-.328-.02-.557.217-.771 1.412-1.277 3.733-1.279 5.129.005.141.13.243.304.396.499"
          transform="matrix(8.33333 0 0 8.33333 57.401549366 16.02454805)"
        />
        <path
          d="m0-1.552c.574.276 1.095.479 1.554.776.134.087.095.442.135.674-.221-.001-.507.086-.651-.02-.728-.534-1.399-.525-2.116.023-.131.099-.424-.015-.642-.03.042-.214.012-.541.138-.62.479-.301 1.009-.521 1.582-.803"
          transform="matrix(8.33333 0 0 8.33333 32.761559222 38.04120591)"
        />
        <path
          d="m-.012-.001c-.267.355-.553.57-.53.747.024.191.32.446.53.479.14.024.463-.283.472-.453.01-.198-.235-.409-.472-.773"
          transform="matrix(8.33173000064 -.16310076809 -.16310076809 -8.33173000064 33.069059099 51.38286724)"
        />
      </g>
    </svg>
  );
};

export default Wifi;
