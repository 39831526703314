import React from "react";

const Cocktails = ({ fill, stroke }) => {
  fill = fill || "#000000";
  stroke = stroke || "#000000";
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 48 53"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
    >
      <g fillRule="nonzero">
        <path
          d="m0-3.449c-.843-.091-.869-.078-.991.513.473.104.876.084.991-.513m-1.248.184c-.028-.064-.057-.129-.085-.193h-1.866c.163.795.774.556 1.226.543.245-.008.484-.227.725-.35m-1.819.762c-.189-.37-.378-.739-.626-1.222.812 0 1.514.022 2.213-.015.197-.01.455-.148.563-.31.489-.731.992-.891 1.96-.525-.387.567-1.431-.248-1.599.804.33.03.64.059 1.051.096-.162.291-.283.535-.428.764-.271.429-.504.898-.845 1.265-.773.831-.67 1.807-.604 2.81.399.041.77.08 1.142.119-.008.07-.015.14-.022.209h-2.645c-.005-.073-.01-.146-.016-.219.357-.032.713-.064 1.07-.096.413-1.497-.239-2.645-1.212-3.687z"
          transform="matrix(8.33333 0 0 8.33333 38.443157476 39.7062062)"
        />
        <path
          d="m0 2.126c-.683.221-1.333-.082-1.504-.701-.173-.628.177-1.299.765-1.463.656-.183 1.21.135 1.402.939-.196-.181-.3-.27-.395-.367-.297-.301-.668-.507-1.021-.213-.244.204-.477.579-.479.878-.002.388.299.685.767.72.159.012.309.139.463.213 0 .001.002-.006.002-.006"
          transform="matrix(8.33333 0 0 8.33333 12.901501026 1.06455499)"
        />
      </g>
    </svg>
  );
};

export default Cocktails;
