import React from "react";

const Terraza = ({ fill, stroke }) => {
  fill = fill || "#000000";
  stroke = stroke || "#000000";
  return (
    <svg
      fill={fill}
      width="100%"
      height="100%"
      viewBox="0 0 108 108"
      version="1.1"
    >
      <g fillRule="non-zero" transform="matrix(1,0,0,1,-13509.7,-2839.81)">
        <g transform="matrix(8.33333,0,0,8.33333,9279.08,0)">
          <g transform="matrix(1,0,0,1,511.283,346.473)">
            <path d="M0,1.803C0.005,1.877 0.011,1.95 0.016,2.024L5.618,2.024C5.616,1.95 5.613,1.877 5.61,1.803L0,1.803ZM-1.72,-2.848L7.318,-2.848C5.484,-4.977 0.215,-5.012 -1.72,-2.848M3.044,-5.696C3.473,-5.536 3.89,-5.31 4.334,-5.226C6.317,-4.852 8.011,-4.07 9.234,-1.972L3.359,-1.972L3.359,0.807C4.05,0.807 4.791,0.673 5.447,0.852C5.91,0.979 6.258,1.53 6.657,1.892C6.288,2.295 5.985,2.914 5.535,3.052C4.883,3.251 4.125,3.103 3.219,3.103C3.577,4.4 2.476,5.861 4.367,6.754C3.328,7.499 2.396,7.159 1.382,6.944C3.007,5.922 2.167,4.49 2.329,3.122C1.583,3.122 0.951,3.152 0.324,3.114C-0.422,3.069 -1.031,2.779 -1.013,1.906C-0.995,1.016 -0.372,0.802 0.388,0.803C0.983,0.804 1.578,0.803 2.257,0.803L2.257,-1.929L-3.612,-1.929C-2.412,-4.083 -0.705,-4.845 1.275,-5.225C1.718,-5.31 2.135,-5.535 2.564,-5.696L3.044,-5.696Z" />
          </g>
        </g>
        <g transform="matrix(8.33333,0,0,8.33333,9279.08,0)">
          <g transform="matrix(1,0,0,1,509.165,353.658)">
            <path d="M0,-8.436C0.067,-8.123 0.18,-7.813 0.193,-7.498C0.222,-6.779 0.203,-6.059 0.203,-5.34C0.204,-3.739 0.209,-3.78 1.813,-3.575C2.156,-3.531 2.618,-3.127 2.726,-2.792C3.003,-1.937 3.111,-1.027 3.286,-0.139C3.093,-0.086 2.901,-0.033 2.709,0.02C2.548,-0.425 2.313,-0.858 2.242,-1.317C2.063,-2.473 1.666,-3.243 0.156,-2.615C0.006,-2.007 -0.16,-1.242 -0.384,-0.494C-0.436,-0.321 -0.685,-0.206 -0.843,-0.065C-0.967,-0.272 -1.211,-0.488 -1.197,-0.685C-1.022,-3.06 -0.816,-5.432 -0.596,-7.803C-0.575,-8.028 -0.407,-8.239 -0.307,-8.456C-0.205,-8.449 -0.102,-8.443 0,-8.436" />
          </g>
        </g>
        <g transform="matrix(8.33333,0,0,8.33333,9279.08,0)">
          <g transform="matrix(1,0,0,1,519.487,353.636)">
            <path d="M0,-8.384C0.044,-8.024 0.1,-7.666 0.13,-7.305C0.307,-5.174 0.491,-3.043 0.637,-0.91C0.657,-0.629 0.477,-0.335 0.389,-0.047C0.21,-0.263 -0.052,-0.452 -0.134,-0.7C-0.344,-1.338 -0.475,-2.001 -0.635,-2.647C-2.316,-2.922 -2.306,-2.919 -2.707,-1.304C-2.82,-0.85 -3.014,-0.416 -3.171,0.027C-3.374,-0.026 -3.578,-0.079 -3.782,-0.132C-3.536,-1.199 -3.359,-2.291 -2.99,-3.314C-2.911,-3.533 -2.134,-3.592 -1.687,-3.569C-0.895,-3.528 -0.622,-3.823 -0.669,-4.603C-0.723,-5.517 -0.699,-6.437 -0.671,-7.354C-0.66,-7.708 -0.542,-8.059 -0.473,-8.411C-0.315,-8.402 -0.158,-8.393 0,-8.384" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Terraza;
