import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './BackgroundImage.module.scss';

function BackgroundImage(props) {
  const { image, alt: altText } = props;
  const imageObj = getImage(image);
  return (
    <div className={styles.backgroundImageWrap}>
      <GatsbyImage
        className={styles.backgroundImage}
        image={imageObj}
        alt={altText}
      />
    </div>
  );
}

BackgroundImage.defaultProps = {
  alt: 'Ramen Kagura Background',
};

BackgroundImage.propTypes = {
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({
        backgroundColor: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  alt: PropTypes.string,
};

export default BackgroundImage;
