import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './EmptyContent.module.scss';

function EmptyContent(props) {
  const { children } = props;
  return (
    <div className={styles.emptyContentContainer}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.noContentMessageWrap}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
EmptyContent.defaultProps = {
  children: <div><h4 className="text-container">No content found.</h4></div>,
};
EmptyContent.propTypes = {
  children: PropTypes.node,
};
export default EmptyContent;
