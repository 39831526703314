import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './BackgroundVideo.module.scss';

function BackgroundVideo(props) {
  const { videoURL } = props;
  return (
    <div className={styles.backgroundVideoWrap}>
      <video className={styles.backgroundVideo} src={videoURL} playsInline loop autoPlay muted />
    </div>
  );
}
BackgroundVideo.propTypes = {
  videoURL: PropTypes.string.isRequired,
};
export default BackgroundVideo;
